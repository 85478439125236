.section {
	&.highlight-clients {
		padding-top: 20rem;

		@include media {
			padding: 18.5rem 0 7rem;
		}

		&.standalone {
			padding: 18rem 0 12rem;
			background-color: $color-gray-light;

			@include media {
				padding: 5.5rem 0 2.5rem;
			}
		}

		&.tools {
			.list-client {
				width: 21.5rem;
				height: 8rem;

				@include media {
					width: 15rem;
					height: 6rem;
				}
			}
		}

		.clients-title {
			@include font-bold;
			font-size: 3.6rem;
			line-height: 1;
			margin-bottom: 17rem;

			@include media {
				font-size: 3rem;
				margin-bottom: 5.6rem;
			}

			strong {
				color: $color-red;
			}
		}

		.clients-list {
			display: flex;
			justify-content: space-between;
			flex-flow: row wrap;
			padding: 0 1rem;
		}

			.list-clear {
				flex: 1 0 100%;
			}

			.list-client {
				position: relative;
				height: 6rem;
				width: 14rem;
				margin-bottom: 6rem;

				@include media {
					width: 12rem;
					height: 4rem;
					margin-bottom: 3rem;
				}

				&.change {
					.client-image {
						transition: opacity 4000ms ease;
						&.current {
							opacity: 0;
						}

						&.next {
							opacity: 1;
						}
					}
				}
			}

				.client-image {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;

					&.next {
						opacity: 0;
					}
				}
	}
}