.section {
	&.contact-nav {
		margin-top: -4.3rem;

		@include media {
			margin-top: -3rem;
		}

		.nav-sections {
			display: flex;
			flex-flow: row wrap;
			box-shadow: 0 .1rem 2rem 0 rgba($color-black, .08);
		}

			.sections-section {
				width: 50%;
				background-color: $color-white;
				padding: 3rem 5rem 2.4rem;
				transition: background-color $transition-hover ease;

				@include mediaMin {
					&:hover {
						background-color: lighten($color-yellow, 20);
					}
				}

				@include media {
					padding: 2.7rem 0 2.6rem;
					text-align: center;

					&:active {
						background-color: darken($color-yellow, 10);
					}

					transition: background-color $transition-superfast ease
				}

				&.active {
					background-color: $color-yellow;
				}
			}

				.section-title {
					@include font-bold;
					font-size: 3rem;
					display: block;
					letter-spacing: -.015em;

					@include media {
						font-size: 1.8rem;
					}
				}

				.section-subtitle {
					color: rgba($color-text, .6);
					line-height: 1.2em;
					margin-top: 1rem;

					@include media {
						display: none;
					}
				}
	}

	&.contact-content {
		padding: 14.5rem 0 8rem;

		@include media {
			padding: 9rem 0 7.8rem;
		}

		.content-wrap {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			@include media {
				display: block;
			}
		}

		.content-alts {
			width: 44rem;
			padding-right: 16rem;
			margin-left: 13.5rem;

			@include media {
				width: auto;
				margin: 9.2rem 0 0;
				padding: 0;
			}
		}

			.alts-title {
				font-size: 3rem;
				line-height: 1.2em;
				margin-bottom: 3.6rem;
			}

			.alts-optwrap {
				+ .alts-optwrap {
					margin-top: 1rem;
				}
			}

			.alts-opt {
				@include mediaMin {
					display: block;
				}
			}

			.alts-note {
				margin-top: 3rem;
				color: rgba($color-text, .6);
				line-height: 1.5em;
			}

		.content-form {
			flex: 1 0 0;
		}

			.form-innerwrap {
				transition: transform $transition-anim ease, opacity $transition-anim ease;
				&.flicker {
					opacity: 0;
					transform: translate3d(0, -.6rem, 0);
				}
			}

			.form-field {
				+ .form-field {
					margin-top: 2.4rem;
				}
			}

			.form-submitwrap {
				margin-top: 4.5rem;
			}

			.form-submit {

				&.complete {
					background-color: $color-success;
					border-color: $color-success;
				}

				.loader-anim .anim-dot {
					background-color: $color-yellow;
				}

				@include mediaMin {
					&:hover, &:active {
						background-color: $color-text;
						color: $color-yellow;
					}

					&:active {
						background-color: darken($color-text, 10);
					}
				}

				&:active {
					background-color: darken($color-text, 20);
					color: $color-yellow;
				}
			}
	}

	&.contact-branches {
		padding: 9.2rem 0 15.7rem;

		@include media {
			padding: 0;
		}

		.branches-list {
			@include media {
				width: calc(100% + 4rem);
				margin: 0 -2rem;
			}
		}

			.list-branch {
				position: relative;
				overflow: hidden;

				@include mediaMin {
					+ .list-branch {
						margin-top: 5.7rem;
					}
				}
			}

				.branch-info {
					position: absolute;
					width: 44rem;
					bottom: 0;
					right: 0;
					display: flex;
					flex-flow: row wrap;

					@include media {
						position: relative;
						width: auto;
					}
				}

					.info-text {
						@include font-medium;
						flex: 1 0 0;
						background-color: $color-white;
						padding: 3rem 2rem 3rem 4rem;

						@include media {
							padding: 2.8rem 1rem 2.6rem 2rem;
						}
					}

						.text-title {
							@include font-bold;
							display: block;
							letter-spacing: -.015em;
							margin-bottom: .6rem;
						}

						.text-address {
							font-size: 1.4rem;
							line-height: 1.5em;
							letter-spacing: -.01em;
						}

					.info-cta {
						@include font-bold;
						padding: 3.2rem 3.4rem 2rem;
						width: 15rem;
						display: block;
						background-color: $color-yellow;
						line-height: 1em;
						transition: background-color $transition-hover ease;

						@include media {
							width: 10.5rem;
							padding: 2.8rem 0 2rem 1.5rem;
						}

						span {
							display: block;
						}

						@include mediaMin {
							&:hover, &:active {
								.cta-arrow {
									transform: translate3d(1.1rem, 0, 0);
								}
							}
						}

						&:active {
							background-color: darken($color-yellow, 10);
						}
					}

						.cta-arrow {
							display: block;
							margin-top: .5rem;
							font-size: 2rem;
							transition: transform $transition-anim ease;
						}
	}
}