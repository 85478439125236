.blog-logo svg{
    transform: scale(.8);
}

.blog-logo svg:hover {
    transform: scale(1);
    cursor: pointer;
}

@media (prefers-reduced-motion:no-preference) {
    .blog-logo {
        animation: blog-logo-spin infinite 4s linear;
    }
}

@keyframes blog-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#circle {
    position: relative;
    width: 120px;
    overflow: hidden;
    border-radius: 50%;
}

#circle text {
    font-family: 'Helvetica Neue', Arial;
    font-size: 14px;
    letter-spacing: 4px;
}

#circlee svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    -webkit-animation-name: rotate;
    -moz-animation-name: rotate;
    -ms-animation-name: rotate;
    -o-animation-name: rotate;
    animation-name: rotate;
    -webkit-animation-duration: 5s;
    -moz-animation-duration: 5s;
    -ms-animation-duration: 5s;
    -o-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -o-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(360deg);
    }
    to {
        -webkit-transform: rotate(0);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(360deg);
    }
    to {
        -moz-transform: rotate(0);
    }
}

@-ms-keyframes rotate {
    from {
        -ms-transform: rotate(360deg);
    }
    to {
        -ms-transform: rotate(0);
    }
}

@-o-keyframes rotate {
    from {
        -o-transform: rotate(360deg);
    }
    to {
        -o-transform: rotate(0);
    }
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0);
    }
}