@keyframes spin {
	from {
		transform: translate3d(-50%, -50%, 0) rotate(0deg);
	}

	to {
		transform: translate3d(-50%, -50%, 0) rotate(360deg);
	}
}

// @keyframes scroller {
// 	0%, 10%, 90%, 100% {
// 		transform: translate3d(0, 0, 0);
// 	}

// 	50%, 55% {
// 		transform: translate3d(0, 5.4rem, 0);
// 	}
// }

@keyframes scroller {
	0%, 10%, 58% {
		transform: translate3d(0, -100%, 0);
	}

	50%, 55%, 90%, 100% {
		transform: translate3d(0, 8rem, 0);
	}

	56% {
		transform: translate3d(100%, 8rem, 0);
	}

	57% {
		transform: translate3d(100%, -100%, 0);
	}

	70% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes loader-bounce {
	0%, 100%, 100%{
		transform: translate3d(0, 0, 0);
	}

	70%{
		transform: translate3d(0, -200%, 0);
	}
}

@keyframes loader-bounce-small {
	0%, 100%, 100%{
		transform: translate3d(0, 0, 0);
	}

	70%{
		transform: translate3d(0, -100%, 0);
	}
}