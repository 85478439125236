.btn {
	@include font-bold;
	position: relative;
	display: inline-block;
	text-align: left;
	
	color: $color-text;
	font-size: 1.6rem;
	line-height: 2.2rem;
	padding: 2.1rem 2.9rem 1.9rem;
	border: .1rem solid $color-text;
	transition: background-color $transition-hover ease, color $transition-hover ease, border-color $transition-hover ease;

	@include mediaMin {
		&:hover, &:active {
			background-color: darken($color-text, 10);
			border-color: darken($color-text, 10);
			color: $color-white;
		}
	}

	@include media {
		transition: background-color $transition-superfast ease, color $transition-superfast ease, border-color $transition-superfast ease;
	}

	&:active {
		background-color: darken($color-text, 20);
		border-color: darken($color-text, 20);
		color: $color-white;
	}

	&.solid {
		background-color: $color-text;
		color: $color-white;

		@include mediaMin {
			&:hover, &:active {
				background-color: darken($color-text, 10);
				color: $color-white;
			}
		}

		&:active {
			background-color: darken($color-text, 20);
			color: $color-white;
		}
	}

	// Colors
	&.white {
		//background-color: $color-white;
		color: $color-white;
		border-color: $color-white;

		@include mediaMin {
			&:hover, &:active {
				background-color: darken($color-white, 10);
				border-color: darken($color-white, 10);
				color: $color-text;
			}
		}

		&:active {
			background-color: darken($color-white, 20);
			border-color: darken($color-white, 20);
			color: $color-text;
		}

		&.solid {
			background-color: $color-white;
			color: $color-text;

			@include mediaMin {
				&:hover, &:active {
					background-color: darken($color-white, 10);
					color: $color-text;
				}
			}

			&:active {
				background-color: darken($color-white, 20);
				color: $color-text;
			}
		}
	}

	// Sizes & Shapes
	&.block {
		width: 100%;
		display: block;
	}

	&.wide {
		min-width: 26.5rem;

		.btn-content {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&.has-info {
		padding-right: 2.6rem;
		padding-left: 2.6rem;
	}

	// States
	&:disabled {
		opacity: 0.5
	}

	&.status-loading {
		&:disabled {
			opacity: 1;
		}
	}

	&.show-status {
		.btn-content {
			transform: scale(0.5);
			opacity: 0;
			transition: opacity $transition-hover ease, transform $transition-hover ease;
		}

		.btn-statuswrap {
			opacity: .8;
			transform: translate3d(-50%, -50%, 0) scale(1);
			transition: opacity $transition-hover ease $transition-fast, transform $transition-hover ease $transition-fast;
		}
	}

	// Inner Elements
	.btn-icon {
		display: inline-block;
		vertical-align: middle;
		font-size: 3rem;
		margin: -.2em 0 -.1em;

		&.pre {
			margin-right: 1.7rem;
			margin-left: -.5rem;

			&.btn-arrow {
				margin-right: 6.6rem;
			}
		}

		&.post {
			margin-left: 1.7rem;
			margin-right: -.5rem;

			&.btn-arrow {
				margin-left: 6.6rem;
			}
		}

		&.btn-arrow {
			font-size: 1.8rem;
			color: $color-red;
		}
	}

	.btn-content {
		display: block;
		width: 100%;
		transition: opacity $transition-hover ease $transition-fast, transform $transition-hover ease $transition-fast;
	}

	.btn-statuswrap {
		opacity: 0;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate3d(-50%, -50%, 0) scale(.5);
		transition: opacity $transition-hover ease, transform $transition-hover ease;
	}

	.btn-loader {
		@include spin;
		font-size: 2.4rem;
		display: block;
		width: 2.4rem;
		height: 2.4rem;
		position: absolute;
		top: 50%;
		left: 50%;
	}

	.btn-status {
		font-size: 2rem;
	}

	.btn-info {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translate3d(0, -50%, 0);
		font-size: 1.4rem;
		height: 1.4rem;
		line-height: 1.4rem;
		opacity: .5;
		transition: opacity $transition-hover ease;

		&:hover {
			opacity: 1;
		}
	}
}