.section.footer {
	background-color: $color-text;
	color: $color-white;
	padding: 8.4rem 0 2.8rem;

	.footer-top {
		display: flex;
		justify-content: space-between;

		@include media {
			flex-flow: row wrap;
			justify-content: flex-start;
		}

		a {
			transition: color $transition-hover ease;
			@include mediaMin {
				&:hover, &:active {
					color: $color-red;
				}
			}

			@include media {
				transition: color $transition-superfast ease;
				
				&:active {
					color: $color-red;
				}
			}
		}
	}

	.footer-bottom {
		padding-top: 12rem;

		@include media {
			padding-top: 5.4rem;
		}
	}

	.footer-highlight {
		font-size: 1.8rem;
		letter-spacing: .05em;

		@include media {
			width: 100%;
			margin-bottom: 6rem;
		}
	}

		.highlight-title {
			@include font-bold;
			font-size: 3rem;
			margin-bottom: 2rem;
			line-height: 1.2em;
		}

			.title-highlight {
				color: $color-red;
			}

		.highlight-link {
		}

	.footer-nav {
		font-size: 1.4rem;
		display: flex;
		flex-flow: row wrap;
		line-height: 2rem;

		> * + * {
			margin-left: 5rem;

			@include media {
				margin-left: 3.4rem;
			}
		}
	}

		.nav-address {
			@include media {
				width: calc(50% - 1.7rem);
			}
		}

			.address-title {
				@include font-medium;
				display: block;
				letter-spacing: -.015em;
				margin-bottom: 1rem;
			}

			.address-text {
				color: rgba($color-white, .4);
			}

		.nav-links {
			@include media {
				margin-top: 6rem;
				margin-left: 0;

				+ .nav-links {
					margin-left: 5.6rem;
				}
			}
		}

			.links-item {
				+ .links-item {
					margin-top: 1.5rem;
				}
			}

	.footer-copyright {
		@include font-light;
		font-size: 1.2rem;
		letter-spacing: .05em;
		color: rgba($color-white, .4);
	}
}