.section.cover {
	overflow: hidden;
	transition: background-color $transition-anim ease;

	&.show {
		.cover-content {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 0ms;
		}

			.content-title {
				strong {
					opacity: 1;
					transform: translate3d(0, 0, 0);
					transition-delay: 0ms;
				}
			}

			.content-description {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-delay: 0ms;
			}

		.cover-scroller {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 250ms;
		}

		.notfound-content {
			opacity: 1;
			transform: translate3d(0, 0, 0);
			transition-delay: 0ms;

			.notfound-title {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-delay: 100ms;
			}

			.notfound-ctawrap {
				opacity: 1;
				transform: translate3d(0, 0, 0);
				transition-delay: 200ms;
			}
		}
	}

	.cover-wrap {
		position: relative;
		padding-top: 13.2rem;
		height: 100vh;
		min-height: 50rem;
		max-height: 84rem;
		display: flex;
		align-items: center;

		@include media {
			padding-top: 10.4rem;
			min-height: 30rem;
			max-height: 41.2rem;
		}
	}

	.cover-content {
		opacity: 0;
		transform: translate3d(0, -1rem, 0);
		transition: opacity $transition-anim ease, transform $transition-long ease, color $transition-anim ease;
	}

		.content-back {
			display: inline-block;
			font-size: 2rem;
			color: $color-red;
			padding: 2rem;
			margin: 0 0 1rem -2rem;

		}

		.content-title {
			@include font-bold;
			letter-spacing: -.015em;
			font-size: 6rem;
			line-height: 6.5rem;

			@include media {
				font-size: 3rem;
				line-height: 3.5rem;
				#cooverMainPage {
					font-size: 2.5rem;
				}
			}

			strong {
				display: inline-block;
				color: $color-red;
				opacity: 0;
				transform: translate3d(0, -.5rem, 0);
				transition: opacity $transition-anim ease, transform $transition-long ease, color $transition-anim ease;
			}
		}

		.content-description {
			@include font-regular;
			font-size: 1.6rem;
			line-height: 2.4rem;
			margin-top: 3rem;
			max-width: 44rem;
			color: rgba($color-white, .6);
			opacity: 0;
			transform: translate3d(0, -.5rem, 0);
			transition: opacity $transition-anim ease, transform $transition-long ease, color $transition-anim ease;;
		}

	.cover-scroller {
		@include font-medium;
		letter-spacing: -.015em;
		color: rgba($color-text, .6);
		position: absolute;
		left: 0;
		bottom: 0;
		display: flex;
		flex-flow: row wrap;
		font-size: 1.4rem;
		opacity: 0;
		transform: translate3d(0, 1rem, 0);
		transition: opacity $transition-anim ease, transform $transition-anim ease;

		span {
			display: inline-block;
			padding-top: 2rem;
		}
	}

		.scroller-indicator {
			position: relative;
			width: .2rem;
			height: 8rem;
			margin: 0 2rem;
			background-color: $color-gray;
			overflow: hidden;

			&:before {
				@include pseudo;
				background-color: $color-red;
				position: absolute;
				top: 0;
				left: 0;
				height: 2.6rem;
				width: 100%;
				animation: scroller 3s infinite cubic-bezier(0.61, 0.18, 0.4, 0.85);
			}
		}

	.notfound-content {
		width: 31.3rem;
		text-align: center;
		margin: 0 auto;
		transition: opacity $transition-anim ease 200ms, transform $transition-long ease 200ms;
		opacity: 0;
		transform: translate3d(0, -1rem, 0);

		.notfound-image {
			width: 100%;
		}

		.notfound-title {
			font-size: 1.4rem;
			letter-spacing: .25em;
			margin-top: 2rem;
			text-transform: uppercase;
			color: rgba($color-text, .3);
			transition: opacity $transition-anim ease 100ms, transform $transition-long ease 100ms;
			opacity: 0;
			transform: translate3d(0, -1rem, 0);
		}

		.notfound-ctawrap {
			margin-top: 5.6rem;
			transition: opacity $transition-anim ease, transform $transition-long ease;
			opacity: 0;
			transform: translate3d(0, -1rem, 0);
		}
	}

	// Types

	&.about, &.services, &.contact, &.servicedetail {
		background-color: $color-text;
		color: $color-white;
	}

	&.careers {
		background-color: $color-yellow;

		.content-title {
			strong {
				color: $color-white;
			}
		}
	}

	/*&.services {
		background-color: $color-green;

		.content-title {
			strong {
				color: $color-white;
			}
		}
	}

	&.contact {
		background-color: $color-blue;

		.content-title {
			strong {
				color: $color-white;
			}
		}
	}*/
}