.section {
	&.hire {
		background-color: $color-yellow;

		.hire-wrap {
			height: 30rem;
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include media {
				display: block;
				padding: 5rem 0;
				height: auto;
			}
		}

		.hire-text {
			@include font-light;
			font-size: 3.6rem;
			line-height: 1.1111111em;
			letter-spacing: -.015em;
			text-align: left;

			@include media {
				padding-bottom: 4rem;
			}

			strong {
				@include font-bold;
			}
		}
	}
}