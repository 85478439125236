.section.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	padding-top: 3.6rem;
	z-index: 10;
	transition: color $transition-hover ease;

	@include media {
		padding-top: 2.3rem;
	}

	&.pg-about, &.pg-services, &.pg-contact, &.pg-servicedetail {
		color: $color-white;

		.logo-image {
			.st0 {
				fill: $color-white;
			}
		}

		.menubtn-inner {
			span {
				background-color: $color-white;
			}
		}
	}

	.header-wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media {
			display: block;
		}
	}

	.header-logo {
		height: 6rem;

		@include media {
			height: 5.5rem;
			width: 5.8rem;
			margin: 0 auto;
			display: block;
		}
	}

		.logo-image {
			height: 100%;

			.st0 {
				fill: $color-text;
				transition: fill $transition-hover ease;
			}

			.st1 {
				fill: $color-red;
			}
		}

	.header-menubtn {
		position: absolute;
		height: 5.5rem;
		width: 7.2rem;
		left: 0;
		bottom: 0;
		z-index: 6;
		display: flex;
		align-items: center;
		justify-content: center;

		&.open {
			border-left-color: transparent;
			transition: border-color $transition-hover ease;
			.menubtn-inner {
				span {
					background-color: $color-white;
					transition-delay: 0ms;

					&:nth-of-type(1) {
						transform: scaleX(0.5) translate3d(-50%, 1rem, 0);
						opacity: 0;
					}

					&:nth-of-type(2) {
						transform: rotate(-45deg);
					}

					&:nth-of-type(3) {
						transform: rotate(45deg);
					}

					&:nth-of-type(4) {
						transform: scaleX(0.75) translate3d(-16%, -1rem, 0);
						opacity: 0;
					}
				}
			}
		}
	}

		.menubtn-inner {
			width: 3.2rem;
			height: 2.4rem;
			position: relative;

			span {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				display: block;
				background-color: $color-text;
				height: .4rem;
				transform-origin: 50% 50% 0;
				transition:
					transform $transition-hover ease,
					opacity $transition-hover ease,
					background-color $transition-hover ease 400ms;

				&:nth-of-type(1) {
					transform: scaleX(.5) translate3d(-50%, 0, 0);
				}

				&:nth-of-type(2), &:nth-of-type(3){
					top: 1rem;
					transform: translate3d(0, 0, 0);
				}

				&:nth-of-type(4){
					top: 2rem;
					transform: scaleX(0.75) translate3d(-16%, 0, 0);
				}
			}
		}

	.header-nav {
		display: flex;
		flex-flow: row wrap;
		align-items: center;

		@include media {
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			background-color: $color-text;
			color: $color-white;
			opacity: 0;
			transition: opacity $transition-hover ease 600ms;
			padding: 15.5rem 2rem 0;

			.nav-item {
				@include font-bold;
				text-transform: none;
				display: block;
				opacity: 0;
				font-size: 3rem;
				line-height: 1em;
				transform: translate3d(-60%, 0, 0);
				transition: opacity $transition-anim-fast ease, transform $transition-anim-fast ease;

				@for $i from 0 through 10 {
					&:nth-of-type(#{$i}) {
						transition-delay: $transition-hover + (50ms * $i);
					}
				}

				+ .nav-item {
					margin: 4rem 0 0;
				}

				> .item-link {
					&:before {
						display: none;
					}

					&.active {
						color: $color-red;
					}
				}
			}

			&.active {
				display: block;

				&.show {
					transition-delay: 0ms;
					opacity: 1;

					.nav-item {
						opacity: 1;
						transform: translate3d(0, 0, 0);

						@for $i from 0 through 10 {
							&:nth-of-type(#{$i}) {
								transition-delay: 50ms * $i;
							}
						}
					}
				}
			}
		}
	}

		.nav-item {
			@include font-medium;
			position: relative;
			letter-spacing: .1rem;
			font-size: 1.2rem;
			text-transform: uppercase;

			&.has-submenu {
				> .item-link {
					&:after {
						@include icon('angle-down');
						margin-left: 1rem;
						font-size: .8rem;
					}

					&:before {
						margin-right: 1.8rem;
					}
				}
			}

			+ .nav-item {
				margin-left: 4rem;
			}

			> .item-link {
				//transition: color $transition-fast ease;

				&.active, &:hover {
					&:before {
						opacity: 1;
						transform: translate3d(0, -50%, 0)!important;
					}
				}

				&.active {
					&:before {
						transition-delay: $transition-superfast;
					}
				}

				&:before {
					@include pseudo;
					position: absolute;
					
					top: 50%;
					left: -.8rem;
					width: 2.5rem;
					height: 2.5rem;
					background-color: $color-red;
					z-index: -1;
					border-radius: 50%;
					opacity: 0;
					transform: translate3d(-30%, -50%, 0);
					transition: opacity $transition-long ease, transform $transition-long ease;
				}

				&.link-to-pages-about {
					&:before {}
				}

				&.link-to-pages-services {
					&:before {
						left: auto;
						right: -.6rem;
						transform: translate3d(30%, -50%, 0);
					}
				}

				&.link-to-pages-careers {
					&:before {
						left: 2.6rem;
					}
				}

				&.link-to-pages-contact {
					&:before {
						left: .9rem;
					}
				}
			}
		}

			.item-link {
				position: relative;
				padding: 1rem 0;
			}

			.item-submenu {
				position: absolute;
				left: -2.5rem;
				top: 100%;
				padding-top: 2rem;
				display: none;
				color: $color-text-light;

				&.active {
					display: block;

					&.show {
						.submenu-content {
							opacity: 1;
							transform: translate3d(0, 0, 0);
						}
					}
				}
			}

				.submenu-content {
					position: relative;
					background: $color-white;
					text-transform: none;
					font-size: 1.4rem;
					padding: 1.4rem .9rem;
					box-shadow: 0rem .1rem 2rem 0rem rgba($color-black, .08);
					opacity: 0;
					transform: translate3d(0, -1rem, 0);
					transition: opacity $transition-hover ease, transform $transition-hover ease;

					&:before {
						@include pseudo;
						width: 0;
						height: 0;
						position: absolute;
						bottom: 100%;
						left: 2.5rem;
						border: .7rem solid transparent;
						border-left-width: .9rem;
						border-right-width: .9rem;
						border-bottom-color: $color-white;
					}
				}

					.content-item {
						+ .content-item {
							margin-top: .3rem;
						}

						.item-link {
							display: block;
							line-height: 1.7rem;
							padding: 1rem 1.4rem;
							background-color: transparent;
							transition: background-color $transition-hover ease;

							@include mediaMin {
								&:hover {
									background-color: rgba($color-red-light, .5);
								}
							}

							&.active {
								background-color: $color-red-light;
							}
							&:active {
								background-color: darken($color-red-light, 5);
							}
						}
					}
}