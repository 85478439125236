.section {
	&.careers-positions {
		padding: 11.5rem 0 16rem;

		@include media {
			padding: 7.5rem 0 9rem;
		}

		.positions-head {
			+ * {
				margin-top: 8rem;

				@include media {
					margin-top: 4rem;
				}
			}
		}

			.head-title {
				font-size: 3rem;
				margin-bottom: 1rem;

				@include media {
					margin-bottom: 2.4rem;
				}
			}

			.head-subtitle {
				color: rgba($color-text, .6);
				line-height: 1.5em;
			}

		.positions-loader {
			padding: 0 0 3rem;
			text-align: center;
		}

		.positions-list {
			&.show {
				.list-position {
					opacity: 1;
					transform: translate3d(0, 0, 0);
				}
			}
		}

			.list-position {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 2.7rem 0;
				border-bottom: .1rem solid rgba($color-black, .2);
				opacity: 0;
				transform: translate3d(0, -.5rem, 0);
				transition: opacity $transition-anim ease, transform $transition-anim ease;

				@include media {
					padding: 2.5rem 0 3rem;
					border-bottom-width: .2rem;
					align-items: flex-start;
				}

				@for $i from 1 through 30{
					&.delay-#{$i}{
						transition-delay: 100ms+(50ms*$i);
					}
				}
			}

				.position-text {}

					.text-title {
						@include font-medium;
						display: block;
						font-size: 2rem;
						margin-bottom: .7rem;
					}

					.text-subtitle {
						color: rgba($color-text, .7);
					}

				.position-applications {}

					.applications-item {
						@include font-medium;
						display: flex;
						flex-flow: row wrap;
						align-items: center;
						font-size: 1.4rem;
						transition: color $transition-hover ease;

						&.linkedin {
							color: $color-linkedin;

							@include mediaMin {
								&:hover, &:active {
									color: darken($color-linkedin, 20);
								}
							}

							@include media {
								transition: color $transition-superfast ease;

								&:active {
									color: darken($color-linkedin, 20);
								}
							}
						}

						i {
							font-size: 2rem;
							margin-right: .8rem;
						}
					}

		.positions-highlight {
			@include font-bold;
			font-size: 3rem;
			margin-top: 3.6rem;

			@include media {
				margin-top: 3rem;
			}

			strong {
				color: $color-red;
				position: relative;

				&:after {
					@include pseudo;
					position: absolute;
					left: 0;
					width: 100%;
					bottom: 0;
					height: .2rem;
					background-color: $color-red;
					opacity: 0;
					transform: scaleX(0);
					transform-origin: 0 0;
					transition: opacity $transition-hover ease, transform $transition-hover ease;
				}
			}
		}

			.highlight-cta {
				transition: color $transition-superfast ease;

				@include mediaMin {
					&:hover, &:active {
						strong {
							&:after {
								transform: scaleX(1);
								opacity: 1;
							}
						}
					}
				}

				@include media {
					&:active {
						color: $color-red;
					}
					//transition: background-color $transition-superfast ease, color $transition-superfast ease, border-color $transition-superfast ease;
				}
			}
	}

	&.careers-features {
		padding: 7rem 0 7.8rem;
		background-color: $color-gray-light;

		@include media {
			padding: 2.6rem 0 3.6rem;
		}

		.features-icons {
			display: flex;
			justify-content: space-between;

			@include media {
				padding: 2rem 2rem;
				margin: 0 -2rem;

				width: calc(100% + 4rem);
				white-space: nowrap;
				display: block;
				overflow: auto;
				overflow-y: hidden;
				overflow-x: scroll;
				-webkit-overflow-scrolling: touch;
			}
		}

			.icons-item {
				text-align: center;
				width: 13rem;

				@include media {
					width: auto;
					display: inline-block;

					+ .icons-item {
						margin-left: 7.5rem;
					}
				}
			}

				.item-icon {
					display: block;
					font-size: 8rem;
				}

				.item-text {
					@include font-bold;
					display: block;
					letter-spacing: -.015em;
					font-size: 1.6rem;
					margin-top: 1.5rem;
				}
	}
}