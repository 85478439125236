@include font-face('NeutrifPro', '../fonts/subset-NeutrifPro-Light', $weight-light);
@include font-face('NeutrifPro', '../fonts/subset-NeutrifPro-Regular', $weight-regular);
@include font-face('NeutrifPro', '../fonts/subset-NeutrifPro-Medium', $weight-medium);
@include font-face('NeutrifPro', '../fonts/subset-NeutrifPro-Bold', $weight-bold);

@mixin font-main{
	font-family: 'NeutrifPro', Arial, Helvetica, sans-serif;
}

@mixin font-light{ font-weight: $weight-light; }
@mixin font-regular{ font-weight: $weight-regular; }
@mixin font-medium{ font-weight: $weight-medium; }
@mixin font-bold{ font-weight: $weight-bold; }

html{
	position: relative;
	font-size: 10px;
	-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important; 
	//background-color: $color-black;

	@include media(1600) {
		@include calcFontSize(1600);
	}

	@include media {
		font-size: 10px;
	}

	@include media (750) {
		@include calcFontSize(375);
	}

	@include mediaLandscape(750, 750) {
		@include calcFontSize(375, true);
	}
}

body{
	@include font-main;
	@include font-regular;
	color: $color-text;
	font-size: 1.6rem;
	opacity: 1;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	//background-color: $color-white;

	&.block-overflow {
		overflow: hidden;
		height: 100vh;
	}
}

/*.router-wrap {
	min-height: calc(100vh - 7rem);

	@include media {
		min-height: calc(100vh - 5rem);
	}
}*/

.site-content {
	max-width: 192rem;
	margin: 0 auto;
}

.wrapper {
	width: $wrapper-regular;
	max-width: calc(100% - 5rem);
	margin: 0 auto;

	&.wide {
		width: $wrapper-wide;
	}

	&.narrow {
		width: $wrapper-narrow;
	}

	@include media {
		max-width: calc(100% - 4rem);
	}
}

.link {
	//color: $color-primary;
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
	letter-spacing: -.015em;
}