.section {
	&.about-intro {
		padding: 11.5rem 0 15rem;

		@include media {
			padding: 7.2rem 0 6.2rem;
		}

		.intro-wrap {
			display: flex;

			@include media {
				display: block;
			}
		}

		.intro-title {
			@include font-bold;
			flex: 1 0 0;
			font-size: 3rem;
			line-height: 1.2em;
		}

		.intro-description {
			width: 55.5rem;
			padding-right: 11.5rem;
			color: rgba($color-text, .6);
			line-height: 1.5em;

			> * + * {
				margin-top: 1em;
			}

			@include media {
				padding-right: 0;
				width: auto;
				margin-top: 3rem;

				> * + * {
					margin-top: 3rem;
				}
			}
		}
	}

	&.about-team {
		padding: 14.8rem 0 21rem;

		@include media {
			padding: 6rem 0 14.5rem;
		}

		.team-wrap {}

		.team-list {
			display: flex;
			flex-flow: row wrap;
			width: calc(100% + 2rem);
			margin: 0 -1rem;
		}

		.team-member {
			padding: 1rem;
			width: 25%;

			@include media {
				width: 50%;
			}
		}

			.member-badge {
				padding: 2.6rem .5rem 2.6rem 2rem;

				@include media {
					padding: 1.4rem 0 3.6rem;
				}
			}

				.badge-name {
					@include font-bold;
					letter-spacing: -.015em;
					display: block;
				}

				.badge-title {
					font-size: 1.4rem;
					color: rgba($color-text, .7);
					margin-top: .3rem;
					line-height: 1.2em;
				}

		.team-join {
			display: block;

			&:hover {
				.join-wrap {
					background-color: $color-red;
					color: $color-white;
				}
			}
		}

			.join-wrap {
				position: relative;
				padding-bottom: 98.87640%;
				transition: color $transition-hover ease, background-color $transition-hover ease;
				color: $color-red;

				&:before {
					@include pseudo;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border: .1rem solid $color-red;
				}
			}

			.join-text {
				@include font-bold;
				position: absolute;
				font-size: 2.4rem;
				line-height: 1.2em;
				letter-spacing: -.025em;
				left: 3.7rem;
				bottom: 2.7rem;

				@include media {
					left: 1.6rem;
					bottom: 1.5rem;
				}
			}

				.text-icon {
					display: block;
					margin-bottom: 2rem;
					font-size: 2.3rem;
				}

				.text-title {
					display: block;
				}
	}
}