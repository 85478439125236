.image {
	&:not(.imagewrap){
		transition: opacity 300ms ease;
	}

	&.image-loading {
		&:not(.imagewrap){
			opacity: 0!important;
		}

		&.imagewrap {
			.imagewrap-image {
				opacity: 0;
			}
		}
	}
}

.imagewrap {
	overflow: hidden;

	&.contain {
		.imagewrap-image{
			background-size: contain;
		}
	}

	&.abs {
		position: relative;

		.imagewrap-image {
			position: absolute;
			left: 0;
			top: 0;
		}
	}

	.imagewrap-image {
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		transition: opacity $transition-hover ease;
	}

	.imagewrap-loaderwrap {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.imagewrap-image {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
		.loaderwrap-icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			color: $color-red;
			font-size: 7rem;
			opacity: .7;
		}
}

.inlinesvg {
	opacity: 0;

	&.loaded {
		opacity: 1;
	}

	svg {
		width: 100%;
	}
}