// Colors
$color-white: #fff;
$color-black: #000;

$color-text: #211f1f;
$color-text-light: #6d6a6a;
//$color-text-negative: $color-white;
//$color-text-negative-light: #8a92a6;

$color-gray-light: #fcfafa;
$color-gray: #e8e8e8;

$color-red: #e01000;
$color-red-light: #ffeceb;
$color-yellow: #fcd469;
$color-green: #aeeda6;
$color-blue: #a6daed;

$color-linkedin: #007ab5;

$color-error: $color-red;
$color-success: #63b861;

// Font Weighting
$weight-extralight: 200;
$weight-light: 300;
$weight-regular: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$fontweight-heading: $weight-bold;
$fontweight-bold: $weight-bold;

$darken-ratio: 7%;

//transition
$transition-superfast: 40ms;
$transition-fast: 180ms;
$transition-hover: 200ms;
$transition-anim-fast: 300ms;
$transition-anim: 400ms;
$transition-long: 600ms;

//radiusses
$radius-general: .3rem;
$radius-general-mobile: .6rem;
$radius-big: .6rem;
$radius-big-mobile: 1.2rem;

//easing
$ease-1: "cubic-bezier(0.32, 0.28, 0, 0.97)";

//shadows
$shadow-general: #{"0rem .3rem 2.0rem 0rem rgba(0, 0, 0, 0.22)"};
$shadow-shallow: #{"0rem .1rem 1.0rem 0rem rgba(0, 0, 0, 0.02); "};

//wrapper widths
$wrapper-wide: 136rem;
$wrapper-regular: 113rem;
$wrapper-narrow: 77rem;