.loader-anim {
	width: 5rem;
	height: 5rem;
	position: relative;
	display: inline-block;

	.anim-dot {
		@include pseudo;
		position: absolute;
		bottom: 0;
		width: 1.4rem;
		height: 1.4rem;
		border-radius: 50%;
		background-color: $color-red;
		animation: loader-bounce 1000ms cubic-bezier(0.68, 0.05, 0.51, 1.5) 500ms infinite;

		&.first {
			left: 0;
		}

		&.mid {
			left: 50%;
			margin-left: -.7rem;
			animation-delay: 160ms;
		}

		&.last {
			right: 0;
			animation-delay: 320ms;
		}
	}

	&.small {
		width: 4.4rem;
		height: 1.6rem;

		.anim-dot {
			width: .8rem;
			height: .8rem;

			&.mid {
				margin-left: -.4rem;
			}

			animation-name: loader-bounce-small;
		}
	}
}