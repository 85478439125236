.section {
	&.services-highlight {
		position: relative;

		.highlight-wrap {
			position: relative;
		}

		.highlight-text {
			display: inline-block;
			text-align: left;
			max-width: 100%;

			.text-title {
				font-size: 3rem;
				line-height: 1.2em;
				letter-spacing: -.015em;
				margin-bottom: 2rem;
			}

			.text-subtitle {
				color: rgba($color-text, .6);
				line-height: 1.5em;
			}

			.text-cta {
				color: $color-red;
				display: inline-block;
				font-size: 1.8rem;
				padding: 1.3rem 2.3rem;
				margin-top: 3rem;
				transition: transform $transition-long ease;

				@include media {
					margin-left: -2.3rem;
					margin-top: 1.5rem;
				}

				&:hover {
					transform: translate3d(.5rem, 0, 0);
				}
			}
		}
	}

	&.services-highlight-performance {
		text-align: center;
		padding: 19rem 0 0;
		z-index: 2;

		@include media {
			padding: 7.5rem 0 0;
		}

		.performance-images {
			margin-top: 10.8rem;
			text-align: center;

			@include media {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-flow: row wrap;
				width: calc(100% + 4rem);
				margin: 7rem -2rem 0;
			}
		}

			.images-image {
				display: inline-block;
				width: calc(50% - .5rem);
				margin-bottom: -4.6rem;
				
				@include mediaMin {
					width: auto;
					height: 36rem;
					+ .images-image {
						margin-left: 2rem;
					}
				}
			}
	}

	&.services-highlight-seo {
		background-color: $color-gray-light;
		padding: 24.5rem 0 16rem;

		@include media {
			padding: 11.6rem 0 23rem; 
		}

		.seo-image {
			position: absolute;
			right: 0;
			bottom: -13.6rem;
			width: 44.7rem;

			@include media {
				width: 22.3rem;
				bottom: -9.3rem;
			}
		}
	}

	&.services-highlight-strategy {
		padding: 26.5rem 0 18rem;

		@include media {
			padding: 15rem 0 30.6rem;
		}

		.highlight-text {
			margin-left: 57.5rem;

			@include media {
				margin: 0;
			}
		}

		.strategy-image {
			position: absolute;
			left: 0;
			top: 0;
			width: 67.4rem;

			@include media {
				top: auto;
				bottom: 0;
			}
		}
	}

	&.services-highlight-data {
		padding: 7.3rem 0 20.4rem;
		z-index: 2;

		@include media {
			padding: 0;

			.highlight-wrap {
				padding: 7rem 0 24.5rem;
			}
		}

		.highlight-text {
			margin-left: 11.5rem;

			@include media {
				margin-left: 0;
			}
		}

		.data-image {
			position: absolute;
			right: 0;
			top: -2rem;
			width: 32.5rem;

			@include media {
				width: 20.5rem;
				top: auto;
				right: -2rem;
				bottom: -9.2rem;
			}
		}
	}

	&.services-highlight-dmb {
		padding: 0;
		background-color: $color-gray-light;

		text-align: right;

		.highlight-wrap {
			padding: 25rem 0 11.5rem;

			@include media {
				padding: 14.6rem 0 17rem;
			}
		}

		.highlight-text {
			width: 53.2rem;
		}

		.dmb-images {
			position: absolute;
			left: 4.4rem;
			top: 50%;
			transform: translate3d(0, -50%, 0);

			@include media {
				left: 0;
				right: 0;
				bottom: 0;
				top: auto;
				transform: none;
			}
		}

		.dmb-image {
			@include media {
				position: absolute;
			}

			&.first {
				width: 26.6rem;

				@include media {
					width: 13.5rem;
					left: 0;
					bottom: 0;
				}
			}

			&.second {
				width: 43.33rem;
				margin-top: 3.1rem;

				@include media {
					right: 0;
					top: -10.6rem;
					width: 21.7rem;
					z-index: 2;
				}
			}
		}
	}

	&.services-highlight-pmb {
		padding: 23.6rem 0 20rem;
		text-align: center;

		@include media {
			padding: 17.5rem 0 10.5rem;
		}
	}

	// Detail
	&.services-detail-sections {
		padding: 18rem 0;

		@include media {
			padding: 10rem 0;
		}

		.sections-wrap {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: flex-start;

			@include media {
				display: block;
			}
		}

		.sections-section {
			width: 32.5rem;

			@include media {
				width: 100%;

				+ .sections-section {
					margin-top: 10rem;
				}
			}
		}

			.section-icon {
				color: $color-red;
				font-size: 6rem;
				display: block;
				margin-bottom: 3rem;
			}

			.section-title {
				margin-top: 3rem;
			}

			.section-content {
				margin-top: 3rem;
				color: rgba($color-text, .6);
				font-size: 1.6rem;
				line-height: 2.4rem;

				> * + * {
					margin-top: 2.4rem;
				}

				strong {
					display: block;
					color: $color-text;
					+ * {
						margin-top: 1rem;
					}
				}

				ul {
					li {
						&:before {
							@include icon('check-round');
							color: $color-green;
							font-size: 1.6rem;
							margin-right: 2rem;
						}

						+ li {
							margin-top: .8rem;
						}
					}
				}
			}
	}
}