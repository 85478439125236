@font-face {
	font-family: 'icomoon';
	src: url('../fonts/icomoon.eot?na5yty');
	src: url('../fonts/icomoon.eot?na5yty#iefix') format('embedded-opentype'),
		url('../fonts/icomoon.ttf?na5yty') format('truetype'),
		url('../fonts/icomoon.woff?na5yty') format('woff'),
		url('../fonts/icomoon.svg?na5yty#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@mixin icon($name, $type: 'self') {
	@if $type=='before' {
		&:before {
			@extend .icon-#{$name}:before;
			@extend %icomoon;
		}
	}

	@else if $type=='after' {
		&:after {
			@extend .icon-#{$name}:before;
			@extend %icomoon;
		}
	}

	@else {
		@extend .icon-#{$name}:before;
		@extend %icomoon;
	}
}

%icomoon,
[class^="icon-"],
[class*=" icon-"] {
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-check-round:before {
	content: "\e915";
}

.icon-telescope:before {
	content: "\e912";
}

.icon-expertise:before {
	content: "\e913";
}

.icon-approach:before {
	content: "\e914";
}

.icon-linkedin:before {
	content: "\e90d";
}

.icon-message:before {
	content: "\e910";
}

.icon-phone:before {
	content: "\e90e";
}

.icon-mail:before {
	content: "\e90f";
}

.icon-check:before {
	content: "\e911";
}

.icon-plus:before {
	content: "\e90c";
}

.icon-angle-up:before {
	content: "\e900";
}

.icon-angle-down:before {
	content: "\e901";
}

.icon-angle-left:before {
	content: "\e902";
}

.icon-angle-right:before {
	content: "\e903";
}

.icon-arrow-left:before {
	content: "\e904";
}

.icon-arrow-right:before {
	content: "\e905";
}

.icon-coffeemaker:before {
	content: "\e906";
}

.icon-computer:before {
	content: "\e907";
}

.icon-gaming:before {
	content: "\e908";
}

.icon-lamp:before {
	content: "\e909";
}

.icon-location:before {
	content: "\e90a";
}

.icon-stopwatch:before {
	content: "\e90b";
}