$vp-trans-short: 500ms;
$vp-trans-long: 800ms;
$vp-delay: 100ms;
$vp-indelay: 200ms;

.viewport-watcher{
	.vw-item{
		opacity: 0;

		&.from-hollow{
			opacity: 0;
		}

		&.from-top{
			opacity: 0;
			transform: translateY(-2.0rem);
		}

		&.from-bottom{
			opacity: 0;
			transform: translateY(2.0rem);
		}

		&.from-left{
			opacity: 0;
			transform: translateX(-2.0rem);
		}

		&.from-right{
			opacity: 0;
			transform: translateX(2.0rem);
		}
	}

	&.in-viewport{
		.vw-item{
			opacity: 1;
			transition: opacity $vp-trans-long ease $vp-indelay;

			&.from-top, &.from-bottom{
				transform: translateY(0);
				transition: opacity $vp-trans-short ease $vp-indelay, transform $vp-trans-long ease $vp-indelay;
			}

			&.from-left, &.from-right{
				transform: translateX(0);
				transition: opacity $vp-trans-short ease $vp-indelay, transform $vp-trans-long ease $vp-indelay;
			}

			&.delay-0{
				transition-delay: 0ms;
			}

			@for $i from 1 through 30{
				&.delay-#{$i}{
					transition-delay: $vp-indelay+($vp-delay*$i);
				}
			}
		}
	}
}