.section {
	&.home-highlight {
		//padding: 12rem 0;
		min-height: 84rem;

		@include media {
			min-height: 0;
		}

		.highlight-wrap {
			position: relative;
		}
	}

	&.home-highlight-who {
		padding: 12rem 0 20rem;
		background-color: $color-text;
		color: $color-white;
		display: flex;

		@include media {
			padding: 7rem 0 0;
		}

		.services-wrap {
			height: 52rem;

			@include media {
				height: auto;
			}
		}

		.who-text {
			width: 32.5rem;
			position: absolute;
			right: 0;
			top: 0;
			line-height: 2.4rem;

			@include media {
				position: static;
				margin-top: 9rem;
				margin-bottom: 8.4rem;
			}
		}

			.text-title {
				@include font-bold;
				font-size: 3.6rem;
				margin-bottom: 3.4rem;

				@include media {
					font-size: 3rem;
					margin-bottom: 2rem;
				}
			}

			.text-content {
				color: rgba($color-white, .6);
			}

			.text-cta {
				margin-top: 3.4rem;

				@include media {
					margin-top: 2.5rem;
				}
			}

		.who-image {
			position: absolute;
			left: 0;
			width: 51.9rem;
			bottom: -25.1rem;

			@include media {
				position: static;
				width: 31.8rem;
				display: block;
				margin: 0 auto -5.4rem;
			}
		}

		.who-info {
			position: absolute;

			@include media {
				position: static;
			}

			&.first {
				left: 0;
				top: 5%;

				@include media {
					margin-left: 3.6rem;
				}
			}

			&.second {
				left: 50%;
				top: 52.5%;
				transform: translate3d(-50%, -50%, 0);

				@include media {
					transform: none;
					text-align: right;
					margin: 3.4rem 4rem 3.4rem 0;
				}
			}

			&.third {
				right: 0;
				bottom: 0;

				@include media {
					margin-left: 7.2rem;
				}
			}
		}

			.info-content {
				text-align: left;
				display: inline-block;
			}

			.info-num {
				@include font-bold;
				color: $color-yellow;
				font-size: 7.2rem;
				display: block;
				letter-spacing: -.015em;
				margin-bottom: 1.8rem;
			}

			.info-text {
				@include font-bold;
				letter-spacing: .25em;
				font-size: 1.4rem;
				text-transform: uppercase;
				color: rgba($color-white, .15);
			}
	}

	&.home-highlight-services {
		padding: 21.5rem 0 0;

		@include media {
			padding: 12rem 0 4.8rem;
		}

		.services-wrap {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}

		.services-info {
			width: 25.6%;
			padding-bottom: 7rem;

			@include media {
				width: 100%;
				padding-bottom: 5rem;
			}
		}

			.info-title {
				@include font-bold;
				font-size: 2.4rem;
				margin-bottom: 1.5rem;
			}

			.info-text {
				color: rgba($color-text, .6);
				line-height: 1.5em;
			}

		.services-title {
			@include font-bold;
			font-size: 3.6rem;
			line-height: 1em;
			color: $color-red;
		}
	}

	&.home-highlight-careers {
		background-color: $color-yellow;
		padding-top: 18rem;

		@include media {
			padding: 7rem 0 0;
		}

		.careers-wrap {
			min-height: 66rem;

			@include media {
				min-height: 0;
				padding-bottom: 28.3rem;
			}
		}

		.careers-top {
			display: flex;
			justify-content: space-between;
			padding-right: 11.5rem;

			@include media {
				display: block;
				padding: 0;
			}
		}

		.careers-title {
			@include font-bold;
			margin-top: -.5rem;
			font-size: 3.6rem;
			line-height: 1em;

			@include media {
				font-size: 3rem;
				letter-spacing: -.027em;
				margin-bottom: 3rem;
				margin-top: 0;
			}

			strong {
				color: $color-white;
			}
		}

		.careers-description {
			width: 32.5rem;
			line-height: 1.5em;
			color: rgba($color-text, .7);
		}

		.careers-icons {
			display: flex;
			justify-content: space-between;
			padding: 12rem 0 14.2rem;

			@include media {
				padding: 2rem 2rem;
				margin: 5rem -2rem;

				width: calc(100% + 4rem);
				white-space: nowrap;
				display: block;
				overflow: auto;
				overflow-y: hidden;
				overflow-x: scroll;
				-webkit-overflow-scrolling: touch;
			}
		}

			.icons-item {
				text-align: center;
				width: 13rem;

				@include media {
					width: auto;
					display: inline-block;

					+ .icons-item {
						margin-left: 7.5rem;
					}
				}
			}

				.item-icon {
					display: block;
					font-size: 8rem;
				}

				.item-text {
					@include font-bold;
					display: block;
					letter-spacing: -.015em;
					font-size: 1.6rem;
					margin-top: 1.5rem;
				}

		.careers-imagewrap {
			position: absolute;
			width: 41.6rem;
			right: 2.3rem;
			bottom: -18.5rem;

			@include media {
				width: 30.7rem;
				left: 50%;
				bottom: -11rem;
				transform: translate3d(-50%, 0, 0);
			}
		}

		.careers-image {
			width: 100%;
			display: block;
		}
	}
}